import './App.css';
import { useAuth0 } from "@auth0/auth0-react";
import LearnList from './components/learnlist';
import VideoList from './components/videolist';
import DemoPlatformFooter from './components/DemoPlatformFooter';
import { Dimmer, Loader, Card, Image } from 'semantic-ui-react';
import LanguageContextProvider from './LanguageContext';

function App() {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
  } = useAuth0();

  const triggerLogout= () => {
    logout({ returnTo: window.location.origin })
  }

  if (isLoading) {
    return (
      <Dimmer blurring inverted active id="innerDimmer">
        <Loader inverted>Loading</Loader>
      </Dimmer>
    )
  }
  if (error) {
    window.location.replace(`${process.env.REACT_APP_ERROR_URL}?client_id=${process.env.REACT_APP_CLIENTID}&error=${error.name}&error_description=${error.message}`);
    return null
  }

  if (isAuthenticated) {
    return (
      <>
        <LanguageContextProvider>
          <Card.Group centered>
              <Card className="info-card">
                <Image src="https://cdn.demo.okta.com/images/learn-demo-okta-logo.png" alt="demo.okta.com logo" className="brandLogo"/>
                <p>This is your centre for all things 'how to' demo.okta.</p>
                <a href="/resources/pdf/demo.okta.com Starter Pack.pdf"><Image src="/resources/Starter-Pack.png" alt="demo.okta.com logo" className="starterPack"/></a>
                <p>If it's your first time here, be sure to check out the <a href="/resources/pdf/demo.okta.com Starter Pack.pdf">Starter Pack</a> for a harbor tour of what we offer.</p>
                <p>If you're looking for our developer documentation, <a href="https://docs.demo.okta.com/">head here.</a></p>
                {user["https://"+process.env.REACT_APP_DOMAIN+"/connection/"] === "partner" &&
                  <p>If you're an Okta Partner, <a href="https://partnercommunity.okta.com/s/products" target="_blank" rel="noreferrer">head here.</a></p>
                }
              </Card>
              <Card className="list-container">
                <LearnList/>
                <VideoList/>
              </Card>
          </Card.Group>
          <DemoPlatformFooter userpicture={user.picture} logoutAction={triggerLogout}/>
        </LanguageContextProvider>
      </>
    );
  } else {
    return loginWithRedirect();
  }
}

export default App;