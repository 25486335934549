import { createContext, useContext, useState } from "react";

const LanguageContext = createContext({language:'', setLanguage: ()=>{}})
const supportedLanguages = ['en','ja']

const LanguageContextProvider = ({children}) => {
    const [language, setLanguage] = useState(supportedLanguages.includes(navigator.language) ? navigator.language : 'en')

    return (
        <LanguageContext.Provider value={{language,setLanguage}}>
            {children}
        </LanguageContext.Provider>
    )
}
export const useLanguageContext = ()=> useContext(LanguageContext)
export default LanguageContextProvider