import React from 'react'
import './videolist.css'
import VideoListJson from './videolist.json'
import { Accordion } from 'semantic-ui-react';
import ReactPlayer from 'react-player'

const VideoList = () => {

    let panels = [];

    VideoListJson.list.map((x,i)=>(
        panels.push({
            key:'video-'+i,
            title:x.title,
            content: { 
                content: 
                <>
                    <div>{x.description}</div>
                    <ReactPlayer playing light={'/resources/video/'+x.thumbnail} controls url={'/resources/video/'+x.video} />
                </> 
            }
        })
    ));

    return (
        <div className="video-list">
            <h2 className="list-title">Video Tutorials</h2>
            <Accordion exclusive={0} panels={panels}/>
        </div>
    );
};
export default VideoList;
