import React from 'react'
import './learnlist.css'
import LearnListJson from './learnlist.json'
import { Card, List, Item, Image } from 'semantic-ui-react';
import { useLanguageContext } from '../LanguageContext';

const LearnList = () => {
    const {language} = useLanguageContext()
    const identiconRoot = 'https://cdn.demo.okta.com/images/identicons-set-v2/gray/svg/';
    const getIcon = (icon) => {
        icon = icon.toLowerCase().replaceAll(' ', '-');
        return identiconRoot+'icon-'+icon+'.svg';
    }
    const getPdf = (pdf) => {
        var languagePath = ""
        if(language !== 'en'){
            languagePath = language+'/'
        }
        return '/resources/pdf/'+languagePath+pdf;
    }

    return (
        <Card className="list-card">
            <h2 className="list-title">Customer Identity Cloud Talking Points</h2>
            <List relaxed divided>
                {LearnListJson.list.map((x,i)=>
                    <Item key={i}>
                        <div className="radius-hover" onClick={()=>{window.open(getPdf(x.link))}}>
                            <Image src={getIcon(x.icon)} width="32" height="32" alt="icon"/>
                            <div className="content text">
                                <div className="header">{i+1}) {x.title}</div>
                                <div className="description">{x.description}</div>
                            </div>
                        </div>
                    </Item>
                )}
            </List>
        </Card>
    );
};
export default LearnList;
