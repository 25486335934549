import React from 'react'
import './DemoPlatformFooter.css'
import { Image, Popup, Grid, Header, Icon, List } from 'semantic-ui-react';
import { useLanguageContext } from '../LanguageContext';

const DemoPlatformFooter = ({userpicture,logoutAction}) => {
    const {language,setLanguage} = useLanguageContext()
    return (
        <div className="footer-container">
        <footer className="footer-right">
            <div className="userControls" >
                <span onClick={logoutAction}>Logout</span>
                <Popup trigger={<Image src={userpicture} avatar size='mini' referrerpolicy='no-referrer'/>} hoverable>
                    <Grid centered divided>
                    <Grid.Column textAlign='center'>
                        <Header as='h4'>Language</Header>
                        <List verticalAlign='middle' relaxed>
                            <List.Item className="userControl" onClick={()=> setLanguage("en")}>
                                {language === 'en' && 
                                    <Icon fitted name='check' />
                                }
                                English
                            </List.Item>
                            <List.Item className="userControl" onClick={()=> setLanguage("ja")}>
                                {language === 'ja' && 
                                    <Icon fitted name='check' />
                                }
                                Japanese
                            </List.Item>
                        </List>
                    </Grid.Column>
                    </Grid>
                </Popup>
            </div>
        </footer>
        </div>
    );
};
export default DemoPlatformFooter;
